import React, { useState } from 'react';
import NoStockForm from './NoStockForm';
import s from "./Card.module.css";
import { useNavigate } from "react-router-dom";
import { CallToAction } from '../CallToAction/CallToAction';
import { toastSuccess } from "../../helpers/index";
import { useDispatch, connect } from 'react-redux';
import { updateCart } from '../../redux/actions';
import { capitalizeWords, formatNumber, correctEncoding } from '../../helpers/index';

function Card({ product, isSanteria }) {
    const [noStockModal, setNoStockModal] = useState(false);
    // Tarjeta de producto

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectDetail = () => {
        if (isSanteria) {
            Navigate(`/Detalle/Objetos/${product.id_santeria}`);
        } else {
            Navigate(`/Detalle/Libro/${product.id_libro}`)
        }
    }

    const handleCart = function () {

        let productsCart = [];

        if (localStorage.getItem('order')) {            // Si hay algo en el localStorage
            productsCart = localStorage.getItem('order');  // Lo traigo
            productsCart = JSON.parse(productsCart);       // Y lo convierto a JSON

            if (productsCart.filter((e) => isSanteria ? e.id_santeria === product.id_santeria : e.id_libro === product.id_libro).length > 0) {  // Si este producto ya existe en el carrito
                productsCart.forEach((p, i) => {
                    if (p.id_santeria === product.id_santeria || p.id_libro === product.id_libro) {
                        p.cant = Number(p.cant) + 1;          // Le sumo los amounts de esta card
                    }
                })
            } else {                                   //  Si no no existe en el carrito
                productsCart.push({ ...product, cant: 1 });  //  Lo pusheo
            }

            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage

        } else {                                           // Si no hay nada en el localStorage
            productsCart.push({ ...product, cant: 1 });    //  Lo pusheo
            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage
        }

        toastSuccess("Producto agregado al carrito");

        dispatch(updateCart());

    }

    const closeNoStockModal = () => {
        setNoStockModal(false);
    }

    return (
        <>
            <div className={s.container}>
                <img alt="Portada del Libro" src={`${process.env.REACT_APP_IMG_API}${product.imagen}`}
                    onClick={redirectDetail} />
                <div className={s.gap} />
                <div className={s.data}>
                    <p className={s.titulo} onClick={redirectDetail}>
                        {capitalizeWords(correctEncoding(product.titulo, isSanteria))}
                    </p>
                    <div>
                        {
                            !isSanteria &&
                            <p className={s.autor}>{capitalizeWords(product.autores)}</p>
                        }

                        {
                            product.precio === product.preciodescuento && (product.preciodescuento != "0.00" && product.precio !== "0.00") &&
                            <p className={s.precio}>{formatNumber(product.precio)}</p>
                        }
                        {
                            product.precio !== product.preciodescuento && (product.preciodescuento != "0.00" && product.precio !== "0.00") &&
                            <div>
                                <p className={s.precioDescuento}>{formatNumber(product.precio)}</p>
                                <p className={s.nuevoPrecio}>{formatNumber(product.preciodescuento)}</p>
                            </div>
                        }
                    </div>
                </div>
                {
                    !isSanteria && product.stockMin < 1 ?
                        <button className={s.noStockButton} onClick={() => setNoStockModal(true)}>
                            Notificarme cuando esté disponible
                        </button>
                        :
                        product.preciodescuento == "0.00" || product.precio == "0.00" ?
                            <CallToAction content="Agregar al Carrito" disabledStyle={true} />
                            :
                            <CallToAction content="Agregar al Carrito" onClick={() => { handleCart(); }} />
                }
            </div>

            <NoStockForm noStockModal={noStockModal} closeNoStockModal={closeNoStockModal} productCode={product.codigo} />
        </>
    )
}

export default connect(null, { updateCart })(Card);