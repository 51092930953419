import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toastSuccess, toastError } from '../../helpers';
import Modal from 'react-modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import axios from 'axios';
import s from "./NoStockForm.module.css";

Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
    overlay: {
        zIndex: 100,
        background: "#00000060",
        height: "100vh",
        width: "100vw",
        margin: "0",
    }
};

function NoStockForm({ noStockModal, closeNoStockModal, productCode }) {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        nombre: "",
        apellido: "",
        email: "",
        celular: ""
    });

    const token = useSelector(state => state.token);

    const handleInputChange = function (e) {
        setInputs((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    }

    const sendForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (validated) {
                const response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/grabarlibrossinstock.php?token=${token}&nombre=${inputs.nombre}&apellido=${inputs.apellido}&email=${inputs.email}&telefono=${inputs.celular}&codigo=${productCode}`);
                if (response.status === 200) {
                    toastSuccess("Mensaje enviado con éxito. Muchas gracias.");
                } else {
                    toastError("Ha ocurrido un error al enviar el mensaje. Pruebe de nuevo más tarde");
                }
            }
        } catch (error) {
            toastError("Ha ocurrido un error al enviar el mensaje. Pruebe de nuevo más tarde");
        } finally {
            closeNoStockModal();
            setLoading(false);
        }
    }

    useEffect(() => {
        let aux = true;
        for (const [_key, value] of Object.entries(inputs)) {
            if (value === "" || !value) {
                aux = false;
            }
        }
        setValidated(aux);
    }, [inputs])

    return (
        <Modal
            isOpen={noStockModal}
            onRequestClose={() => closeNoStockModal()}
            style={modalStyles}
            ariaHideApp={false}
            className={s.noStockModalContainer}
        >
            <form className={s.formModal} onSubmit={(e) => { sendForm(e) }}>
                <p>Artículo temporalmente sin stock. Complete sus datos.</p>
                <div className={s.inputContainer2}>
                    <input id="nombre" type='text' placeholder='Nombre' required size="1" value={inputs.nombre} onChange={handleInputChange} />
                </div>
                <div className={s.inputContainer2}>
                    <input id="apellido" type='text' placeholder='Apellido' required size="1" value={inputs.apellido} onChange={handleInputChange} />
                </div>
                <div className={s.inputContainer2}>
                    <input id="email" type='email' placeholder='Email' required size="1" value={inputs.email} onChange={handleInputChange} />
                </div>
                <div className={s.inputContainer2}>
                    <input id="celular" type='text' placeholder='Celular' required size="1" minLength={10}
                        pattern="\d{10,}" value={inputs.celular} onChange={handleInputChange} 
                        onInvalid={(e) => (e.target).setCustomValidity("Debe contener al menos 10 números")}
                        onInput={(e) => e.target.setCustomValidity("")}
                    />
                </div>
                <div className={s.btnsContainerModal}>
                    <button type='submit' className={validated ? s.btnSubmit : s.btnSubmitDisabled}>
                        {
                            loading ?
                                <LoadingSpinner contactSection={true} isWhiteColor={true} />
                                :
                                "Enviar"
                        }
                    </button>
                </div>
            </form>
        </Modal>
    )
}

export default NoStockForm;