import React, { useEffect, useState } from 'react';
import s from "./SelectPago.module.css";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastError } from "../../../helpers/index";
import Modal from 'react-modal';
import { ArrowBackIos } from '@mui/icons-material';
import { formatNumber } from '../../../helpers/index';

Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
    overlay: {
        zIndex: 100,
        background: "#00000060",
        height: "100vh",
        width: "100vw",
        margin: "0",
    }
};

export default function SelectPago({ nextStep, totalEnvio, numeroPedido }) {

    // Este componente muestra la dos opciones de pago y ejecuta la funcion parametro nextStep(selected) donde selected es "transferencia" o "mercadopago"
    const token = useSelector(state => state.token);
    const Navigate = useNavigate();
    const [selected, setSelected] = useState(null);
    const [total, setTotal] = useState(0);
    const [condicionMP, setCondicionMP] = useState(false);
    const [linkMP, setLinkMP] = useState("");
    const [modalCountdownMP, setModalCountdownMP] = useState(false);
    const [count, setCount] = useState(5);

    useEffect(() => {
        let productsCart = [];
        productsCart = localStorage.getItem('order');
        productsCart = JSON.parse(productsCart);
        let resultado = 0;
        let productWithNoMP = false;
        
        for (let i = 0; i < productsCart.length; i++) {
            if (productsCart[i].mercadoPago == 1) {
                const isSanteria = productsCart[i].id_santeria ? true : false;

                if ((productsCart[i].preciodescuento == productsCart[i].precio)) {
                    if (!isSanteria) {
                        queryMercadopago();
                        setCondicionMP(true);
                    } else if (isSanteria && productsCart[i].stockMin > 5) {
                        queryMercadopago();
                        setCondicionMP(true);
                    } else {
                        productWithNoMP = true;
                    }
                } else {
                    productWithNoMP = true;
                }
            } else {
                productWithNoMP = true;
            }
            resultado = resultado + (Number(productsCart[i].preciodescuento) * Number(productsCart[i].cant));
        }

        if (productWithNoMP) {
            setCondicionMP(false);
        }

        if (totalEnvio) {
            setTotal(Number(totalEnvio) + Number(resultado));
        } else {
            setTotal(Number(resultado));
        }

    }, [numeroPedido])

    const queryMercadopago = async function () {
        // LLamada al servicio de MP (con nro pedido)
        let productosCarrito = [];
        productosCarrito = localStorage.getItem('order');
        productosCarrito = JSON.parse(productosCarrito);

        let query = `${process.env.REACT_APP_URL_API}servicios/getmp.php?token=${token}&libros=`;

        for (let i = 0; i < productosCarrito.length; i++) {
            query = query + `${productosCarrito[i].codigo};${productosCarrito[i].cant};${productosCarrito[i].precio}`;

            if ((productosCarrito.length - 1) !== i) {
                query = query + "|";
            }
        }

        query = query + `&nroPedido=${numeroPedido}&costoenvio=${totalEnvio}`;
        try {
            await fetch(query, {
                method: 'GET'
            })
                .then(response => response.json())
                .then(data => {
                    setLinkMP(String(data.response_code));
                });
        } catch (e) {
            toastError("Ocurrió un error inesperado, intentalo mas tarde");
            Navigate("/");
        }
    }

    function delay(milliseconds) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, milliseconds);
        });
    }

    useEffect(() => {
        const countdown = setInterval(() => {
            setCount(prevCount => prevCount - 1);
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(countdown);
    }, []);

    const navigateMP = async function () {
        setCount(5);
        setModalCountdownMP(true);
        await delay(5000);
        setModalCountdownMP(false);
        window.location.href = `${linkMP}`;
    }

    return (
        <div className={s.container}>
            <h2>Pago</h2>
            <div className={s.option} onClick={() => { setSelected('transferencia'); }}>
                <div className={s.row}>
                    <input type='radio' name='pago' value="transferencia" checked={selected === "transferencia"} onChange={(e) => { setSelected(e.target.value); }} />
                    <p>Transferencia</p>
                </div>
                <div className={s.row}>
                    <p className={s.precio}>{formatNumber(total)}</p>
                </div>
            </div>
            {
                condicionMP == true &&
                <div className={s.option} onClick={() => { setSelected("mercadopago"); }}>
                    <div className={s.row}>
                        <input type='radio' name='pago' value="mercadopago" checked={selected === "mercadopago"} onChange={(e) => { setSelected(e.target.value); }} />
                        <p>Mercadopago</p>
                    </div>
                    <div className={s.row}>
                        <p className={s.precio}>{formatNumber(total)}</p>
                    </div>
                </div>
            }<div className={s.btnsContainer}>
                <div className={s.btnBackContainer} onClick={() => { nextStep('', "2", ''); }}>
                    <ArrowBackIos sx={{ fontSize: 16 }} />
                    <p className={s.btnBack}>Volver atrás</p>
                </div>
                {
                    selected === "mercadopago" ?
                        <button onClick={(e) => { e.preventDefault(); navigateMP(); }}
                            className={selected !== null ? s.btnSubmit : s.btnSubmitDisabled}>
                            Proceder al pago
                        </button>
                        :
                        <button onClick={(e) => { e.preventDefault(); selected !== null && nextStep(selected, "", "", condicionMP ? "1" : "2"); }}
                            className={selected !== null ? s.btnSubmit : s.btnSubmitDisabled}>
                            Realizar pedido
                        </button>
                }
            </div>

            {/* Modal de Countdown para MP */}
            <Modal
                isOpen={modalCountdownMP}
                onRequestClose={() => { }}
                style={modalStyles}
                ariaHideApp={false}
                className="modalLoadingContainer"
            >
                <div className={s.modalContainer}>
                    <div className={s.modalLoading}>
                        <h4>Último paso!</h4>
                        <p>Será redirigido al sitio de Mercadopago para completar el proceso.</p>
                        <h1>{count}</h1>
                    </div>
                </div>
            </Modal>
        </div>
    )
}